<template>
    <div class="content_area">
        <div class="content_wpr">
            <div class="container">
                <div class="white_box">
                    <div class="section_content w-100">
                        <div class="section_header">
                            <h2>Security<span>Update Email, Password or add Two Factor Authentication</span></h2>
                        </div>
                        <div class="billing">
                            <div class="setting_wpr border-bottom">
                                <div class="point_header"><i class="fas fa-envelope"></i>Email Address</div>
                                <div class="para">This is for your billing email address. You can change this email address by click the change button.</div>
                                <div class="change_box">
                                    <span>{{ billingProfile.email }}</span>
                                    <button type="button" @click="toggleEmailPopup()">Change Email</button>
                                </div>
                            </div>
                            <div class="setting_wpr">
                                <div class="point_header"><i class="fas fa-lock"></i>Password</div>
                                <div class="para">You can change password by click the change button.</div>
                                <div class="change_box">
                                    * * * * * * * * *
                                    <button type="button" @click="togglePasswordPopup()">Change Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setting_wpr">
                    <div class="billing_card">
                        <div class="header">Two Factor Authentication</div>
                        <div class="card_body">
                            <p>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.</p>
                            <p>To utilize two factor authentication, you must install the <strong>Google Authenticator</strong> application on your smartphone.</p>
                            <button type="button" @click="toggleTwoFAPopup()" v-if="user.google2fa_secret">Disable Authentication</button>
                            <button type="button" @click="toggleTwoFAPopup()" v-else>Enable Authentication</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal secondary security" v-if="changePassword">
        <div class="modal_container">
            <h3 class="sub_header m-0">
                Change Password
            </h3>
            <div class="setting_wpr">
                <Form @submit="handlePasswordUpdate" v-slot="{ errors }" class="profile-form">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Current Password</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.current_password }">
                                <Field type="password" :autocomplete="'nofill'" name="current_password" v-model="passwordForm.current_password" rules="required|min:6" label="current password" />
                            </div>
                            <ErrorMessage name="current_password" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">New Password</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.password }">
                                <Field type="password" :autocomplete="'nofill'" name="password" v-model="passwordForm.password" rules="required|min:8" label="password" />
                            </div>
                            <ErrorMessage name="password" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Re-enter Password</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.password_confirmation }">
                                <Field type="password" :autocomplete="'nofill'" name="password_confirmation" v-model="passwordForm.password_confirmation" rules="required|min:8" label="password confirmation" />
                            </div>
                            <ErrorMessage name="password_confirmation" class="text-danger" />
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="passwordChangeLoader" type="button" class="btn cancel_btn" @click="changePassword = false">Cancel</button>
                        <button :disabled="passwordChangeLoader" class="btn save_btn">
                            <i class="fa fa-spinner fa-spin" v-if="passwordChangeLoader"></i>
                            {{ passwordChangeLoader ? 'Saving' : 'Save' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <div class="modal secondary security" v-if="changeEmail">
        <div class="modal_container">
            <h3 class="sub_header m-0">
                Change Billing Email
            </h3>
            <div class="setting_wpr">
                <Form @submit="handleEmailUpdate" v-slot="{ errors }" class="profile-form">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Current Password</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.current_password }">
                                <Field type="password" :autocomplete="'nofill'" name="current_password" v-model="emailForm.current_password" rules="required|min:8" label="current password" />
                            </div>
                            <ErrorMessage name="current_password" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">New Email Address</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.email }">
                                <Field type="email" :autocomplete="'nofill'" name="email" v-model="emailForm.email" rules="required|email" placeholder="Enter New Email Address" />
                            </div>
                            <ErrorMessage name="email" class="text-danger" />
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="emailChangeLoader" type="button" class="btn cancel_btn" @click="changeEmail = false">Cancel</button>
                        <button :disabled="emailChangeLoader" class="btn save_btn">
                            <i class="fa fa-spinner fa-spin" v-if="emailChangeLoader"></i>
                            {{ emailChangeLoader ? 'Saving' : 'Save' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <div class="modal secondary security" v-if="enableTwoFA">
        <div class="modal_container">
            <h3 class="sub_header m-0">
                Two Factor Authentication
            </h3>
            <div class="setting_wpr">
                <div v-if="twoFACodeLoader" class="twofa-wrapper">
                    <quote-loader />
                </div>
                <template v-else>
                    <div v-if="user.google2fa_secret">
                        <Form @submit="handleTwoFADisable" v-slot="{ errors }" class="profile-form">
                            <p>2FA is Currently <strong>Enabled</strong> for your account.</p>
                            <p>If you are looking to disable Two Factor Authentication. Please confirm your password and Click Disable 2FA Button.</p>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Current Password</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.current_password }">
                                        <Field type="password" :autocomplete="'nofill'" name="current_password" v-model="twoFAForm.current_password" rules="required|min:8" label="current password" placeholder="Enter Current Password" />
                                    </div>
                                    <ErrorMessage name="current_password" class="text-danger" />
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button :disabled="twoFALoader" type="button" class="btn cancel_btn" @click="enableTwoFA = false">Cancel</button>
                                <button :disabled="twoFALoader" class="btn save_btn">
                                    <i class="fa fa-spinner fa-spin" v-if="twoFALoader"></i>
                                    {{ twoFALoader ? 'Disabling' : 'Disable 2FA' }}
                                </button>
                            </div>
                        </Form>
                    </div>
                    <div v-else>
                        <Form @submit="handleTwoFAEnable" v-slot="{ errors }" class="profile-form">
                            <Field type="hidden" name="google2fa_secret" v-model="twoFAForm.google2fa_secret" />
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Current Password</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.current_password }">
                                        <Field type="password" :autocomplete="'nofill'" name="current_password" v-model="twoFAForm.current_password" rules="required|min:8" label="current password" placeholder="Enter Current Password" />
                                    </div>
                                    <ErrorMessage name="current_password" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">OTP</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.otp }">
                                        <Field type="password" :autocomplete="'nofill'" name="otp" v-model="twoFAForm.otp" rules="required" placeholder="One Time Password" />
                                    </div>
                                    <ErrorMessage name="otp" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp justify-center">
                                <p>Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code {{ twoFACode.google2fa_secret }}</p>
                                <div v-html="twoFACode.google2fa_qr_image"></div>
                                <p>You must set up your Google Authenticator app before continuing. You will be unable to login otherwise</p>
                            </div>
                            <div class="action_wpr">
                                <button :disabled="twoFALoader" type="button" class="btn cancel_btn" @click="enableTwoFA = false">Cancel</button>
                                <button :disabled="twoFALoader" class="btn save_btn">
                                    <i class="fa fa-spinner fa-spin" v-if="twoFALoader"></i>
                                    {{ twoFALoader ? 'Authenticating' : 'Authenticate' }}
                                </button>
                            </div>
                        </Form>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>

<script>
    import Toastr from '@/utils/Toastr'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        data () {
            return {
                billingProfile: {},
                changePassword: false,
                passwordChangeLoader: false,
                changeEmail: false,
                emailChangeLoader: false,
                passwordForm: {},
                emailForm: {},
                twoFAForm: {
                    google2fa_secret: '',
                    current_password: '',
                    otp: '',
                },
                enableTwoFA: false,
                twoFALoader: false,
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            twoFACode (twoFACode) {
                const vm = this;

                vm.twoFAForm.google2fa_secret = twoFACode.google2fa_secret;
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            twoFACode: state => state.billingModule.twoFACode,
            twoFACodeLoader: state => state.billingModule.twoFACodeLoader,
        }),

        methods: {
            ...mapActions ({
                generateTwoFACodes: 'billingModule/generateTwoFACode',
                connectTwoFA: 'billingModule/enableTwoFA',
                disconnectTwoFA: 'billingModule/disableTwoFA',
                refreshAuth: 'authModule/refreshAuth',
                updatePassword: 'authModule/changePassword',
                updateBillingEmail: 'billingModule/changeBillingEmail',
                getBillingProfile: 'billingModule/getBillingProfile',
            }),

            toggleEmailPopup () {
                const vm = this;

                vm.changeEmail = true;
            },

            togglePasswordPopup () {
                const vm = this;

                vm.changePassword = true;
            },

            toggleTwoFAPopup () {
                const vm = this;

                if (!vm.user.google2fa_secret) {
                    vm.generateTwoFACodes();
                }

                vm.enableTwoFA = true;
            },

            handlePasswordUpdate (form, { setFieldError }) {
                const vm = this;
                const params = vm.passwordForm;
                params.setFieldError = setFieldError;

                vm.passwordChangeLoader = true;

                vm.updatePassword(params).then((result) => {
                    if (result) {
                        vm.passwordForm = {};
                        vm.changePassword = false;
                    }

                    vm.passwordChangeLoader = false;
                });
            },

            handleEmailUpdate (form, { setFieldError }) {
                const vm = this;
                const params = vm.emailForm;
                params.setFieldError = setFieldError;

                vm.emailChangeLoader = true;

                vm.updateBillingEmail(params).then((result) => {
                    if (result) {
                        vm.getBillingProfile();
                        vm.emailForm = {};
                        vm.changeEmail = false;
                    }

                    vm.emailChangeLoader = false;
                });
            },

            handleTwoFAEnable (form, { setFieldError }) {
                const vm = this;
                const params = vm.twoFAForm;
                params.setFieldError = setFieldError;

                vm.twoFALoader = true;
                vm.connectTwoFA(params).then((result) => {
                    if (result) {
                        vm.twoFAForm = {};
                        vm.refreshAuth();
                        Toastr.success('Two factor authentication successfully enabled to your account!')
                        vm.enableTwoFA = false;
                    }

                    vm.twoFALoader = false;
                });
            },

            handleTwoFADisable (form, { setFieldError }) {
                const vm = this;
                const params = vm.twoFAForm;
                params.setFieldError = setFieldError;

                vm.twoFALoader = true;
                vm.disconnectTwoFA(params).then((result) => {
                    if (result) {
                        vm.enableTwoFA = false;
                        vm.refreshAuth();
                        Toastr.success('Two factor authentication successfully disabled for your account!')
                    }

                    vm.twoFALoader = false;
                });
            },
        },
    }
</script>

<style scoped>
    .container{
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        padding: 15px;
    }
    .white_box{
        background: #fff;
        border-radius: 8px;
        padding: 10px 10px 0 10px;
    }
    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    .twofa-wrapper .quote_wpr {
        width: 100%;
        min-height: 50vh;
        justify-content: center;
    }

    .justify-center {
        justify-content: center;
        text-align: center;
    }
</style>
